<template>
  <div>
    <div class="banner">
      <img src="../assets/images/about-bg.png" alt="" />
    </div>
    <div class="about-wrap">
      <div
        class="about-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        关于我们
      </div>
      <div
       style='text-align:left'
        class="about-mes fadeOutUp sd"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
      北京小凌泰科科技有限公司（以下简称：小凌泰科）成立于2017年，是一家专注于行业软件产品研发的高科技企业。目前公司的主要业务集中在能源、通信、工业互联网等领域，并致力于围绕数据标识，构建标识解析、确权登记、数据交易、资产入表等数据资产全生命周期产品服务体系，助力企业数据资产价值释放。

      </div>

      <div
      style='text-align:left'
        class="about-mes fadeOutUp sd"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >

      小凌泰科具有成熟的软件开发能力，并通过CMMI5认证。我们在工业互联网、电力、通信等领域基于现有基础产品提供个性化的集成服务和解决方案。在国内面向中国工业互联网研究院、中航工业集团、国家电网公司等大型企事业单位，为其提供领先的软件产品、解决方案及服务，并受到客户广泛好评。
      </div>
      <div
       style='text-align:left'
        class="about-mes fadeOutUp sd"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
      2022年，公司持续加大工业互联网方向产品研发和业务拓展的力度，特成立苏州小凌互联科技有限公司（简称：小凌互联）。经过近一年的快速发展，小凌互联已经实现了工业互联网标识解析完整的产品体系，并在横向场景应用打造、纵向产业数据融合的方向上不断深入发展。

      </div>
      <div class="about-img">
        <img
          class="fadeOutUp"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
           style="transition-delay:.1s"
          src="../assets/images/about-img_1.png"
          alt=""
        />
        <img
          class="fadeOutUp"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
           style="transition-delay:.2s"
          src="../assets/images/about-img_2.png"
          alt=""
        />
      </div>
      <div class="about-content">
        <div
          class="about-item fadeOutUp"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          style="transition-delay: 0.1s"
        >
          <div class="about-icon">
            <img
              style="width: 100px"
              src="../assets/images/about-icon_1.png"
              alt=""
            />
          </div>
          <div class="about-name">使命</div>
          <div class="about-tip">推进数据要素市场化</div>
        </div>
        <div
          class="about-item fadeOutUp"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
          style="transition-delay: 0.2s"
        >
          <div class="about-icon">
            <img
              style="width: 101px"
              src="../assets/images/about-icon_2.png"
              alt=""
            />
          </div>
          <div class="about-name">愿景</div>
          <div class="about-tip">成为领先的数据资产服务提供商</div>
        </div>
        <div
          class="about-item fadeOutUp"
          ref="animate6"
          :class="{ fadeInUp: animateData.animate6 }"
          style="transition-delay: 0.3s"
        >
          <div class="about-icon">
            <img
              style="width: 92px"
              src="../assets/images/about-icon_3.png"
              alt=""
            />
          </div>
          <div class="about-name">战略</div>
          <div class="about-tip">打造完整的数据资产产品服务体系</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../utils/index.js";

export default {
  data() {
    return {
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
      },
    };
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
.about-wrap {
  padding: 29px 0 49px;
  background: #fff;
  position: relative;
  .about-title {
    color: #414B59;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
  }
  .about-mes {
    width: 995px;
    margin: 30px auto 25px;
    font-size: 16px;
    color: #909399;
    line-height: 34px;
    text-align: center;
  }
}
.about-img {
  display: flex;
  width: 995px;
  margin: 0 auto;
  justify-content: space-between;
  img {
    width: 490px;
  }
}
.about-content {
  display: flex;
  justify-content: space-between;
  margin: 42px auto 0;
  width: 1180px;
  height: 338px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
  border-radius: 8px;
  padding: 59px 40px 0;
  .about-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-icon {
    width: 123px;
    height: 123px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-name {
    color: #243159;
    font-size: 18px;
    margin: 23px 0 14px;
    line-height: 24px;
    font-weight: bold;
  }
  .about-tip {
    font-size: 14px;
    color: #63697b;
    line-height: 34px;
  }
}
</style>
